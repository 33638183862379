@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

@media print {

    html {
        /*-webkit-print-color-adjust: exact;*/
        /*-webkit-filter: opacity(1);*/
        font-family: sans-serif, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    .prevent-page-break {
        page-break-inside: avoid;
    }

    .pagebreak {
        clear: both;
        page-break-after: always;
    }
}

/*@page {*/
/*    margin: 0cm !important;*/
/*}*/

@page {
    margin-top: 0.5in;
    margin-bottom: 0.5in;
}

@page :first {
    margin-top: 0cm !important;
}
